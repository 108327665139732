<template>
  <div class="wrapper" v-if="getClaims && getMe && initialized && getConfig">
    <sidebar-menu :linkTo="{ name: 'home-dashboard' }">
      <ul>
        <li v-if="(claims.root || claims.admin)">
          <a href="#dropdown-admin" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle">
            <i class="far fa-cog"></i>{{ $t('menu.admin.settings') }}</a>
          <ul class="collapse" id="dropdown-admin">
            <router-link v-if="getPages.trackAndTrace &&  (claims.root  || claims.admin || (user.email && user.email.includes('motivate.nl') ))" tag="li"
                         :to="{name:'admin'}" exact>
              <a><i class="fad fa-tasks-alt"></i>{{  $t('menu.admin.trackAndTrace')  }} </a>
            </router-link>
          </ul>

        </li>



      </ul>
    </sidebar-menu>

    <topbar style="z-index: 300">
      <template slot="left">
        <a href="javascript:" id="sidebarCollapse">
          <i class="fal fa-bars"/>
        </a>


      </template>

      <template slot="right">
        <ul>

          <li class="dropdown">
            <a data-toggle="dropdown" href="javascript:" aria-expanded="false">
              <i class="fad fa-user"/>
              <span class="userLoggedIn">{{ loggedInUser }}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right mt-0">


              <div class="dropdown-divider"></div>
              <form class="px-4 py-3">
                <div class="form-group">
                  <label for="Langu"> {{ $t("ui.setLanguage") }}</label>
                  <select
                      name=""
                      id=""
                      @change="setLanguage($event)"
                      class="form-control"
                  >
                    <option value="en" :selected="getMe.locale === 'en'">
                      Engels
                    </option>
                    <option value="nl" :selected="getMe.locale === 'nl'">
                      Nederlands
                    </option>
                  </select>
                </div>
              </form>

              <div class="dropdown-divider"></div>

              <!--              <router-link v-if="userClaims().admin || userClaims().root" :to="{name:'root-usermanagement'}" class="dropdown-item">Beheer</router-link>-->
              <a href="javascript:"
                 v-show="!this.$store.getters.getLoadingScreen"
                 class="dropdown-item" @click="logOut()"
              >{{ $t("ui.logout") }}
              </a>
            </div>
          </li>
        </ul>
      </template>
    </topbar>

    <div id="m-content" @click="hideMenu()">
      <transition name="fade">
        <router-view/>
      </transition>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {LoadingScreen, SidebarMenu, Topbar} from 'motivate-framework-components';
import {mapGetters, mapMutations} from 'vuex';
// For later purposes https://vue-bottom-navigation.herokuapp.com/
// import VueBottomNavigation from "bottom-navigation-vue";
import {increment} from 'vuex-easy-firestore'
import MotivateLogoWrapper from "../components/trackAndTrace/MotivateLogoWrapper.vue";
//@vuese
// @group Wrappers
// Main page wrapper
export default {
  name: "Home",
  components: {
    MotivateLogoWrapper,
    SidebarMenu,
    LoadingScreen,
    Topbar

  },
  data() {
    return {
      initialized: false,
      user: this.$FirebaseAuth.getUser(),
      companyConfig: null,
      menuShown: true,
      latestImport: {}
    };
  },
  firestore() {

    return {
      companyConfig: this.$FB.collection("companies").doc(this.claims ? this.claims.companyId : "null"),
    };
  },
  computed: {
    ...mapGetters("users", ["getMe", "getClaims", "getLocalData"]),
    ...mapGetters("company", ["getConfig", "getPages"]),

    getLatestImport(){
      return this.latestImport
    },
    //@vuese
    // returns app version, this is based on the version from package.json
    getAppVersion() {
      if (process.env.NODE_ENV === "production") {
        return this.$APP_VERSION;
      }
      return this.$APP_VERSION + "-DEV";
    },
    //@vuese
    // Gets the current user claims
    claims() {
      const claims = this.getClaims;
      return claims !== undefined ? claims : null;
    },
    //@vuese
    // returns the current logged in user
    loggedInUser() {
      // let user = this.$store.state.users.me;
      // console.log(this.getMyAssignedImprovements)
      let user = this.user;
      return user ? user.displayName : "";
    },
  },
  methods: {
    ...mapMutations("insights", ["toggleEditingMode"]),
    async setLanguage(event) {
      this.$root.$i18n.locale = event.target.value;
      await this.$store.dispatch("userData/patch", {
        locale: event.target.value,
      });
      this.swalToastSuccess(" Language switched");
      // let response = await this.$swal({
      //   title: 'Select language',
      //   input: 'select',
      //   inputOptions: {
      //     'en': 'English',
      //     'nl': 'Nederlands'
      //   },
      //   inputValue: this.getLocale,
      //   inputPlaceholder: 'Select a langauge',
      //   showCancelButton: true,
      //   inputValidator: (value) => {
      //     return new Promise((resolve) => {
      //       resolve()
      //     })
      //   }
      // })
      // if (response.value) {
      //   if (response.value !== this.getLocale) {
      //     this.$root.$i18n.locale = response.value
      //     await this.$store.dispatch('userData/patch', {locale: response.value})
      //     this.swalToastSuccess(' Language switched')
      //   }
      // }
    },
    async pickDepartment() {
      let departments = {};

      _.forEach(this.getMyDepartments, (dp) => {
        let departmentToShow =
            this.getMe.defaultDepartment == dp ? dp + " (default)" : dp;
        departments[dp] = departmentToShow;
      });


      let response = await this.$swal({
        title: this.$root.$t("topbar.selectDepartment"),
        input: "select",
        inputOptions: {
          ...departments,
        },
        inputValue: this.getMe.activeDepartment
            ? this.getMe.activeDepartment
            : this.getMe.defaultDepartment,
        inputPlaceholder: this.$root.$t("topbar.selectDepartment"),
        showCancelButton: true,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            resolve();
          });
        },
      });
      if (response.value) {
        if (response.value !== this.getMe.activeDepartment) {
          await this.$store.dispatch("userData/patch", {
            activeDepartment: response.value,
          });
          this.$store.commit("users/selectedWorkflow", null);
          this.swalToastSuccess(" Department switched");
        }
      }
    },
    hideMenu() {
      $("#m-sidebar, #m-content, #m-navbar-top").addClass("active");
      $(".collapse.in").addClass("in");
      $("a[aria-expanded=true]").attr("aria-expanded", "false");

      if ($(window).width() < 1199) {
        $("#m-sidebar, #m-content, #m-navbar-top").removeClass("active");
        $(".m-sidebar-overlay").addClass("m-none");
        $(".collapse.in").addClass("in");
        $("a[aria-expanded=true]").attr("aria-expanded", "false");
      }

      if ($(window).width() < 768) {
        $(".m-sidebar-overlay").addClass("m-none");

        $("#m-sidebar, #m-content, #m-navbar-top").removeClass("active");
        $(".collapse.in").addClass("in");
        $("a[aria-expanded=true]").attr("aria-expanded", "false");
      }
    },
    onClassChange(element, callback) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
              mutation.type === 'attributes' &&
              mutation.attributeName === 'class'
          ) {
            callback(mutation.target);
          }
        });
      });
      observer.observe(element, {attributes: true});
      return observer.disconnect;
    },
    //@vuese
    // Logs out current user. Then sets user in store to null and closes db channels
    async stopRunningJobsOfWorkflow() {

    },
    async logOut() {
      const logoutDestination = this.$store.getters['getLogoutDestination'];
      const stopJobAfterLogout = this.$store.getters['getStopJobOnLogOut'];
      if (stopJobAfterLogout) {
        /**
         * Todo: met rik bespreken hoe we dit doen, moeten alle jobs van die user gestopt worden?
         * Of alleen de jobs die gestart zijn via de kiosk?
         * Of alleen van de workflow die je koos via de kiosk?
         * En keuze geven of het een pauze is en/of gestopt is?
         * */
      }
      try {

        // await this.$store.dispatch('userData/patch', {activeDepartment: null})
        await this.$store.dispatch("closeApp").catch((e) => {
          console.log(e);
        });
        await this.updateTimespent();
        await this.$FirebaseAuth.signOut();
        await this.$store.commit("users/setMe", null);
      } catch (e) {
        console.error(e);
      }
      this.$router.push({name: logoutDestination.routerName, params: logoutDestination.params});
    },

    async updateTimespent() {
      const updatedUser = await this.$FirebaseAuth.getCurrentUser();
      const lastLogin = updatedUser.metadata.b;
      const currentTime = Date.now();
      const sessionTime = currentTime - lastLogin;

      await this.$store.dispatch("userData/patch", {
        id: this.user.id,
        totalTimeInPlatform: increment(sessionTime),
      });
    },

    showVersionInfo() {
      this.$swal({
        title: "Hey there curious user",
        text: "This application is running version " + this.getAppVersion,
        confirmButtonText: "Let me get back to work",
      });
    },
  },
  watch: {
    //@vuese
    // keeps track of the current
    companyConfig(newV) {
      Vue.prototype.$COMPANY_CONFIG = newV;
    },
  },

  async created() {
    let user = await this.$FirebaseAuth.getCurrentUser();


    try {
      this.$FB_MESSAGING.onMessage((res) => {
        // let notification = res.data.notification;
        // notification = JSON.parse(notification);
        // ServiceWorkerRegistration().showNotification(notification.title, notification)
        // return new Notification(notification.title,
        //     notification
        // );
      });
    } catch (e) {
    }
    if (user) {
      await this.$store.dispatch("initApp");
      this.initialized = true;

    }
    var self = this
    setTimeout(() => {
      var itemToWatch = document.querySelector('#m-sidebar');

      if (itemToWatch) {
        this.onClassChange(itemToWatch, (node) => {
          self.menuShown = !node.classList.contains('active')

        });
      }
    }, 5000)
  },
};
</script>
<style lang="scss">
@import "./../assets/scss/_customFramework";
@import "./../assets/scss/main.scss";
@import "./../assets/scss/mobile/mobileSettings";

.versioning-info {
  opacity: 0.3;
  position: absolute;
  bottom: 10px;
  padding: 10px 10px 10px 20px;

  a {
    color: white;
  }
}

@media only screen and (max-device-width: $maxTabletWidth) {
  .desktop-department {
    display: none !important;
  }

  .mobile-department {
    display: unset !important;
  }
}

</style>
